module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"George Hazell & Co Ltd","short_name":"George Hazell & Co Ltd","start_url":"/","background_color":"#1e7a99","theme_color":"#1e7a99","display":"minimal-ui","icon":"src/images/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f8d9af5a52f61dc309259df8a546cb29"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5aVXVYNkJFQUFDUUFYdTE4.G2fvv719Xe-_ve-_ve-_ve-_vWk177-9Be-_ve-_ve-_vQLvv70i77-977-977-977-977-977-9BkLvv71XQykh","repositoryName":"hazell","routes":[{"type":"page","uid":"index","path":"/:lang?/"},{"type":"page","path":"/:lang?/:uid/"},{"type":"blogpost","path":"/:lang?/blog/:uid/"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
